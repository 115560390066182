import React, { Fragment, useEffect } from "react"
import { PageProps } from "gatsby"
import { useOrganization } from "src/hooks"

function TermsAndConditions(props: PageProps) {
	const organization = useOrganization()

	useEffect(() => {
		if (organization?.terms_and_conditions_link) {
			window.open(organization.terms_and_conditions_link, '_blank')
		}
		window.location.href = '/'
	}, [organization?.terms_and_conditions_link])

	return <Fragment />
}

export default TermsAndConditions